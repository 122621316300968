import Image from 'next/image';
import React, { Component } from 'react';
const date = new Date();
import parsePhoneNumber from 'libphonenumber-js';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import Link from 'next/link';
import { connect } from 'react-redux';

import logoAWS from '../../../../static/images/AWS-Logo.jpg';
import logo from '../../../static/images/icon/logo-transparent.png';
import logoPaypal from '../../../static/images/icon/paypal.png';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.handleScroll = this.handleScroll.bind(this);
	}
	componentDidMount() {
		document.addEventListener('scroll', this.handleScroll);
	}
	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll);
	}
	onScroll() {
		console.log('scroll in about page');
	}

	handleScroll = () => {
		if (document.documentElement.scrollTop > 600) {
			document.querySelector('.tap-top').style = 'display: block';
		} else {
			document.querySelector('.tap-top').style = 'display: none';
		}
	};

	clickToTop() {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}

	render() {
		const { isAuthenticated, geolocation } = this.props;
		let backcolor = { background: '#eeeeee' };
		let tap_to_top = { display: 'none' };

		var facebookUrl = 'https://www.facebook.com/1800battery';
		var instagramUrl = 'https://instagram.com/1800battery';
		var twitterUrl = 'https://twitter.com/1800battery';
		var youTubeUrl = 'https://youtube.com/channel/1800battery';
		var logoDefault = logo;
		var footerHeadline = 'The largest Battery Marketplace in North America. Over 10,000 products, 250,000 locations, and 1,000,000 needs served. From Cars to Coin Cells we have everything available.';
		var phoneNumber = '(800)228-8379';
		var googleNumber = 'tel:+18002288379';
		var emailAddress = 'info@1800battery.com';
		var name = '1-800-Battery';
		if (this.props.masterProvider && this.props.masterProvider.isActive) {
			footerHeadline = this.props.masterProvider?.footerHeadline || footerHeadline;
			logoDefault = this.props.masterProvider?.imageUrl || logoDefault;
			facebookUrl = this.props.masterProvider?.socialSettings?.facebook || facebookUrl;
			instagramUrl = this.props.masterProvider?.socialSettings?.instagram || instagramUrl;
			twitterUrl = this.props.masterProvider?.socialSettings?.twitter || twitterUrl;
			youTubeUrl = this.props.masterProvider?.socialSettings?.youtube || youTubeUrl;
			phoneNumber = this.props.masterProvider?.phoneNumber || phoneNumber;
			emailAddress = this.props.masterProvider?.emailAddress || emailAddress;
			name = this.props.masterProvider?.name || name;
		}

		googleNumber = findPhoneNumbersInText(phoneNumber, 'US');
		if (googleNumber.length > 0) {
			googleNumber = parsePhoneNumber(googleNumber[0].number.number);
			phoneNumber = googleNumber.formatNational();
			googleNumber = googleNumber.getURI();
		}

		return (
			<footer className="footer-light">
				<section className="section-b-space light-layout">
					<div className="container">
						<div className="row footer-theme partition-f">
							<div className="col-lg-4 col-md-6">
								<div className="footer-contant">
									<div className="footer-logo">
										<Link href={`/`} as={`/`}>
											<a>
												<Image
													width="200px"
													height="58px"
													src={logoDefault}
													alt="footer-logo"
													className="lazyload"
												/>
											</a>
										</Link>
									</div>
									<p>{footerHeadline}</p>
									<div className="footer-social">
										<ul>
											<li>
												<a
													href={facebookUrl}
													aria-label="Facebook"
													target="_blank"
													rel="noreferrer">
													<i className="fab fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a
													href={twitterUrl}
													aria-label="Twitter"
													target="_blank"
													rel="noreferrer">
													<i className="fab fa-twitter" aria-hidden="true" />
												</a>
											</li>
											<li>
												<a
													href={instagramUrl}
													aria-label="Instagram"
													target="_blank"
													rel="noreferrer">
													<i className="fab fa-instagram" aria-hidden="true" />
												</a>
											</li>
											<li>
												<a
													href={youTubeUrl}
													aria-label="YouTube"
													target="_blank"
													rel="noreferrer">
													<i className="fab fa-youtube-square"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>MY ACCOUNT</h4>
									</div>
									<div className="footer-contant">
										<ul>
											{isAuthenticated && (
												<>
													<li>
														<Link href={`/dashboard`} as={`/dashboard`}>
															<a>
																<i className="fad fa-user-cog"></i> Dashboard
															</a>
														</Link>
													</li>

													<li className="logout">
														<Link href={`/logout`} as={`/logout`}>
															<a>
																<i className="fad fa-sign-out-alt"></i> Logout
															</a>
														</Link>
													</li>
												</>
											)}

											{!isAuthenticated && (
												<>
													<li>
														<Link href={`/login`} as={`/login`}>
															<a>
																<i className="fad fa-sign-in-alt"></i> Login
															</a>
														</Link>
													</li>

													<li>
														<Link href={`/register`} as={`/register`}>
															<a>
																<i className="fas fa-user-plus"></i> Register
															</a>
														</Link>
													</li>
												</>
											)}
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>QUESTIONS</h4>
									</div>
									<div className="footer-contant">
										<ul>
											<li>
												<Link href={`/shipping-and-returns`} as={`/shipping-and-returns`}>
													<a>Shipping & Returns</a>
												</Link>
											</li>
											<li>
												<Link
													href={`/frequently-asked-questions`}
													as={`/frequently-asked-questions`}>
													<a>Frequently Asked Questions</a>
												</Link>
											</li>
											<li>
												<Link href={`/termsofuse`} as={`/termsofuse`}>
													<a>Terms of Use</a>
												</Link>
											</li>
											<li>
												<Link href={`/returnpolicy`} as={`/returnpolicy`}>
													<a>Return Policy</a>
												</Link>
											</li>
											<li>
												<Link href={`/disclaimers`} as={`/disclaimers`}>
													<a>Disclaimers</a>
												</Link>
											</li>
											<li>
												<Link href={`/privacy`} as={`/privacy`}>
													<a>Privacy Policy</a>
												</Link>
											</li>
											<li>
												<a target="_blank" href={`https://www.affirm.com/disclosures`} rel="noreferrer">
												Affirm Financing Disclosure
												</a>
											</li>
											<li>
												<Link href="#" class="termly-display-preferences">Consent Preferences</Link>
											</li>
											<li>
												<Link href="https://app.termly.io/notify/2512fd1e-9e4f-4d67-982b-06ada61d6399" class="termly-display-preferences">Do Not Sell or Share My Personal information</Link>
											</li>
											<li>
												<Link href="https://app.termly.io/notify/2512fd1e-9e4f-4d67-982b-06ada61d6399" class="termly-display-preferences">Limit the Use Of My Sensitive Personal Information</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>Business</h4>
									</div>
									<div className="footer-contant">
										<ul>
											<li>
												<Link href={`/add-a-installer`} as={`/add-a-installer`}>
													<a>Become an Installer</a>
												</Link>
											</li>

											<li>
												<Link
													href={`/category/[categoryid]`}
													as={`/category/roadside-assistance`}>
													<a>Roadside Assistance</a>
												</Link>
											</li>
											<li>
												<Link href={`/locations`} as={`/locations`}>
													<a>Locations</a>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="sub-title">
									<div className="footer-title">
										<h4>About</h4>
									</div>
									<div className="footer-contant">
										<ul>
											<li>
												<Link href="/about">
													<a>About {name}</a>
												</Link>
											</li>

											<li>
												<a href="/cities/vehicle-batteries-car-or-truck">
													Mobile Car & Truck Battery Replacement Service in Your City
												</a>
											</li>

											<li>
												<Link href="/cities/vehicle-batteries-hybrid-electric">
													<a>Hybrid Electric Battery Replacement</a>
												</Link>
											</li>

											<li>
												<a href={googleNumber}>{phoneNumber}</a>
											</li>

											<li>
												<a href={`mailto:${emailAddress}?Subject=Inquiry%20from%20Website`}>
													{emailAddress}
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="sub-footer ">
					<div className="container">
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-12">
								<div className="footer-end">
									<p className="pb-0">
										<i className="fa fa-copyright" aria-hidden="true" />
										Copyright &copy; {date.getFullYear()} {name} Inc All Rights Reserved. <br />
										{name}® is a Registered Trademark.
									</p>
								</div>
							</div>
							<div className="col-xl-6 col-md-6 col-sm-12">
								<div className="payment-card-bottom">
									<ul>
										<li>
											<div
												className="trustedsite-trustmark"
												data-type="212"
												data-width="120"
												data-height="50"></div>
										</li>
										<li>
											<span id="siteseal"></span>
										</li>

										<li>
											<a href="#">
												<Image
													width="160px"
													height="55px"
													src={logoPaypal}
													alt="Visa MasterCard American Express "
												/>
											</a>
										</li>

										<li>
											<a href="https://aws.amazon.com/what-is-cloud-computing">
												<Image
													width="120px"
													height="53px"
													className="lazyload"
													src={logoAWS}
													alt="Powered by AWS Cloud Computing"
												/>
											</a>
										</li>
										<li></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="tap-top top-cls" onClick={this.clickToTop} style={tap_to_top}>
					<div>
						<i className="fa fa-angle-double-up" />
					</div>
				</div>
			</footer>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: Boolean(state.customer && state.customer.first_name),
	customer: state.customer,
	geolocation: state.geolocation,
});

export default connect(mapStateToProps)(Footer);
